<template>
  <div class="footer" :class="$route.name === 'appRule'?'bg-gray':'bg-white'">
    <div class="footer-content p0">
      <!-- <div class="left"> -->
      <div class="item keep-px">
        <div class="li-title">{{ $t('components.WebFooter.709891-0') }}</div>
        <div class="li" @click="Nav('serviceArea')">{{ $t('components.WebFooter.709891-1') }}</div>
        <div class="li" @click="Nav('helpCenter')">{{ $t('components.WebFooter.709891-2') }}</div>
        <div class="li" @click="Nav('appRule')">{{ $t('components.WebFooter.709891-3') }}</div>
      </div>
      <div class="item keep-px">
        <div class="li-title">{{ $t('components.WebFooter.709891-4') }}</div>
        <div class="li">{{ $t('components.WebFooter.709891-5') }}</div>
        <div class="li">
          {{ $t('components.WebFooter.544986-0') }}<br>{{ $t('components.WebFooter.544986-1') }}
        </div>
      </div>
      <!-- </div> -->
      <div class="right">
        <svg-icon icon-class="zmzq1" width="200px" height="64px" />
      </div>

    </div>
    <div class="copyright keep-px">
      <span>{{ $t('components.WebFooter.709891-8') }}</span>
      <!-- <el-link href=""  target="_blank" type="info">{{ $t('components.WebFooter.709891-8') }}</el-link> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
      Nav (name) {
        this.$router.push({name})
      }
    }
  }
</script>

<style lang="scss" scoped>
.bg-gray{
  background-color: #F6F8FB;
}
.bg-white{
  background-color: var(--color-white);
}
.footer{
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .p0{
    padding: 60px 360px 100px;
  }
  .p1{
    padding: 60px 240px 100px;
  }
  .footer-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    & >.left{
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    & > .right{
      .logo{
        width: 200px;
      }
    }
    .keep-px{
      font-size: 12px; /* px-to-viewport-ignore */
    }
    .item{
      // width: 50%;
      text-align: left;
      .li-title{
        /* px-to-viewport-ignore-next */
        font-size: 16px;
        line-height: 1.8;
        color: var(--color-black);
      }

      .li{

        line-height: 1.8;
        color: var(--color-gray);
        cursor: pointer;
        // white-space: nowrap;

      }
    }
  }
  .keep-px{
    font-size: 12px; /* px-to-viewport-ignore */
  }
  .copyright{
    width: 100%;
    border-top: 1px solid var(--color-border);
    padding: 20px 0;
    color: var(--color-gray);
    box-sizing: border-box;
  }
}
</style>