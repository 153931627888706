import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
import mLayout from "@/layout/mIndex";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误
//获取原型对象上的push函数


const routes = [
  {
    path: '/mobile',
    name: 'mobile',
    component: mLayout,
    redirect: 'phoneIndex',
    children: [
      {
        path: '/phoneIndex',
        name: 'phoneIndex',
        component: () => import("@/views/mobile/PhoneHome"),
        meta: { title: '首页' }
      },
      {
        path: 'pabout',
        name: 'paboutUs',
        component: () => import("@/views/mobile/AboutUs"),
        meta: { title: '关于我们' }
      },
      {
        path: 'pservice',
        name: 'pserviceArea',
        component: () => import("@/views/mobile/ServiceArea"),
        meta: { title: '服务范围' }
      },
      {
        path: 'phelp',
        name: 'phelpCenter',
        component: () => import("@/views/mobile/HelpCenter"),
        meta: { title: '帮助中心' }
      },
      {
        path: 'prule',
        name: 'pappRule',
        component: () => import("@/views/mobile/AppRule"),
        meta: { title: '免责声明' }
      }
    ]
  },
  {
    path: '/',
    // name: 'web',
    component: layout,
    children: [
      {
        path: '',
        name: 'web',
        component: () => import("@/views/web/Home"),
        meta: { title: '首页' }
      },
      {
        path: 'about',
        name: 'aboutUs',
        component: () => import("@/views/web/AboutUs"),
        meta: { title: '关于我们' }
      },
      {
        path: 'service',
        name: 'serviceArea',
        component: () => import("@/views/web/ServiceArea"),
        meta: { title: '服务范围' }
      },
      {
        path: 'help',
        name: 'helpCenter',
        component: () => import("@/views/web/HelpCenter"),
        meta: { title: '帮助中心' }
      },
      {
        path: 'rule',
        name: 'appRule',
        component: () => import("@/views/web/AppRule"),
        meta: { title: '免责声明' }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
