<template>
  <div class="header">
    <div class="logo">
      <svg-icon icon-class="zmzq" width="200px" height="100px"/>
    </div>
    <div class="tabbar">
      <div
class="tab-item keep-px" :class="t == nav_index ? 'active' : 'default'" v-for="(nav, t) in navBar" :key="t"
        @click="changeNav(t)">
        <img v-if="t == nav_index" class="icon"  :src="require('@/assets/images/home/icon-active.png')" alt="" srcset="">
        <span class="text">{{ nav.name }}</span>
      </div>
    </div>
    <div class="header-right">
      <el-dropdown @command=" (e) => { handleClick(e); } ">
        <span class="el-dropdown-link">
          {{ lang_name }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" command="hover">
          <el-dropdown-item v-for="(lang, l) in languageSelect" :key="l" :command="lang">{{ lang.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      nav_index: 0,
      navBar: [
        { name: this.$t('components.WebHeader.003094-0'), path: 'web' },
        { name: this.$t('components.WebHeader.003094-1'), path: 'aboutUs' },
        { name: this.$t('components.WebHeader.003094-2'), path: 'serviceArea' },
        { name: this.$t('components.WebHeader.003094-3'), path: 'helpCenter' }
      ],
      languageSelect: [
        { name: '简体', key: 'zh' },
        { name: '繁體', key: 'cht' },
        { name: 'English', key: 'en' }
      ]
    }
  },
  computed:{
    lang_name(){
      let name = '繁體'
      this.languageSelect.map((el)=>{
        if(el.key===window.localStorage.getItem("lang")){
          name= el.name
        }
      })
      return name
    }
  },
  methods: {
    changeNav (index) {
      this.nav_index = index;
      this.$router.push({
        name: this.navBar[index].path
      });
    },
    handleClick(e){
      this.languageSelect.map((el)=>{
        if(el.key===e.key){
          this.$i18n.locale = e.key
          window.localStorage.setItem('lang', e.key)
          this.$router.go(0)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background-color: #fff;
  padding: 0 30px 0 0;
  box-sizing: border-box;

  .logo {
    width: 240px;
    height: 100px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color-main);
    padding: 0 20px;
    box-sizing: border-box;
  }

  .tabbar {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .default {
      color: rgba(22, 33, 49, 0.8);
    }

    .active {
      color: rgba(22, 33, 49, 1);
      font-weight: bold;
    }
    .keep-px{
      font-size: 14px; /* px-to-viewport-ignore */
    }

    .tab-item {
      position: relative;
      padding: 0 20px;

      cursor: pointer;
    }
    .icon{
      position: absolute;
      left: 14px;
      top: -3px;
      width: 18px;
      height: 14px;
      z-index: 0;
    }
    .text{
      position: relative;
      z-index: 10;
    }
  }

  .header-right {
    cursor: pointer;
    padding-right: 200px;
  }
}</style>